::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #edc35a; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


.loader_component {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11111;
    background-color: #1b005e9e;
}

.button_group{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}
.button_group button{
    margin-left: 10px;
    border: none;
    outline: none;
    background-color: black;
    color: #fff;
    font-size: 14px;
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button_group .close_btn{
    background-color: red !important;
    color: #fff;
}
.sticky-bar.stick .logo img{
width: 60%;
}
.your-order-product-info-new{
    padding: 20px !important;
}
.your-order-product-info-new img{
    width: 45px;
}
.your-order-product-info-new .table{
    margin-bottom: 0 !important;
}
.first-col{
    display: flex;
    align-items: center;

}
.first-col img{
    margin-left: 10px;
}
.first-col-1 .mb-0.mb-3{
    padding: 0 !important;
    margin-bottom: 0 !important;
}
.first-col-1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.your-order-product-info-new ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dee0e4;
    margin-bottom: 2px;
}
.first-col-1 .form-check-input{
    margin-left: 10px;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.first-col-1 .form-check-input:checked{
    background-color: #edc35a;
    border-color: #edc35a;
}
.header-offer ul{
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
}
.header-offer ul li{
    padding: 10px 10px;
}
.header-offer ul li:last-child{
    padding-right: 0;
}
.header-offer ul li a{
    font-size: 14px;
}
.footer-logo{
    margin-top: 0;
}
.footer-logo img{
    width: 140px;
}
.like_btn.active{
    color:red;
}
.cart-plus-minus_new{
    padding: 0 10px !important;
}
.status_order{
    width: 250px;
}
.status_order a{
    text-decoration: none;
    color: green;
    font-size: 14px;
    background-color: #edc35a;
    color: #fff;
    padding: 8px 10px;
}
.footer-widget .subscribe-style .subscribe-form input:focus{
    box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.slider-height-1{
    position: relative;
}
.slider-height-1::after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    content: '';
    z-index: 1;
}
.slider-content-2 {
    z-index: 111;
    color: #fff ;
    position: relative;
}
.product-wishlist-cart button{
    width: 160px;
}
.offcanvas-menu-close:hover{
    background-color: #edc35a;
}
.breadcrumb-area{
    background-image: url('../images/shopping_banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.breadcrumb-item.active{
    color: #fff !important;
}
.breadcrumb-item + .breadcrumb-item::before{
    color: #fff !important;
}
.blog-wrap-2 .blog-img-2{
    height: 220px;
}
.blog-wrap-2 .blog-img-2 img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
span{
    font-family: "Poppins", sans-serif !important;
}
.iframe_youtube{
    width: 100%;
    height: 600px;
    margin-top: 50px;
}
.iframe_facebook_btn{
    margin-top: 30px;
}
.iframe_facebook_btn a{
    font-size: 14px;
    border: 1px solid #000;
    padding: 10px 20px;
   
}
@media (max-width: 991px)
{
    .stick .header-right-wrap {
        margin-top: 0;
        justify-content: flex-end !important;
    }
    .stick .logo {
        margin-top: 0;
        justify-content: flex-start !important;
    }
}

@media(max-width:576px){
    .slider-content-2 h1, .slider-content-2 .h1{
        font-size: 30px;
    }

}
@media(max-width:320px){
    .cart-shiping-update-wrapper .cart-shiping-update > a, .cart-shiping-update-wrapper .cart-clear > button, .cart-shiping-update-wrapper .cart-clear > a {
        margin: 0 0 15px;
        padding: 18px 18px 17px;
        font-size: 12px !important;
    }
}
#btnclose{
    width: 30px;
    height: 30px;
    background-color: #fff !important;
}